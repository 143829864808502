export const BaseUrl = "https://shiftnpay.com/api/v1";
export const ImgUrl = "https://shiftnpay.com/api/images"
export const socketUrl = "wss://shiftnpay.com:5001";
export const docUrl = "https://shiftnpay.com/api/"


// export const BaseUrl = "http://localhost:4000/v1"
// export const ImgUrl = "http://localhost:4000/images"
// export const socketUrl = "http://localhost:4000";
// export const docUrl = "http://localhost:4000/"
