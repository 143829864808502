import moment from "moment";

export const formatTime = (seconds) => {
  // Ensure `seconds` is a valid number
  // if (typeof seconds !== undefined || isNaN(seconds) || seconds < 0) {
  //     return "00:00:00";
  // }

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  // Format as HH:mm:ss
  const formattedTime = [
    hours.toString().padStart(2, "0"),
    minutes.toString().padStart(2, "0"),
    secs.toString().padStart(2, "0"),
  ].join(":");

  return formattedTime;
};

export const formatDateTime = (dateTime) => {

    const date = new Date(dateTime);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedHours = hours.toString().padStart(2, "0");
    return `${formattedHours}:${minutes}:${seconds} ${ampm}`;
};

export const decodeBase64Url = (base64Url) => {
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
            .join("")
    );
    return JSON.parse(jsonPayload);
};

export const convertSecondsToHHMMSS = (sec) => {
    if (!sec) {
        return `00:00:00`;
    }
    let totalSeconds = Math.round(sec);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = (totalSeconds % 3600) % 60;

    // Format hours, minutes, and seconds to be two digits
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
export const processWeeklyData = (response, overTimeRate = 0, rate) => {
    if (!response) return [];

    const MAX_HOURS = 40;
    const OVERTIME_MULTIPLIER = overTimeRate;
    const SECONDS_IN_AN_HOUR = 3600;
    const SECONDS_IN_A_MINUTE = 60;

    const formatDuration = (totalSeconds) => {
        if (totalSeconds <= 0) return "N/A";

        const hours = Math.floor(totalSeconds / SECONDS_IN_AN_HOUR);
        const minutes = Math.floor((totalSeconds % SECONDS_IN_AN_HOUR) / SECONDS_IN_A_MINUTE);
        const seconds = Math.floor(totalSeconds % SECONDS_IN_A_MINUTE);

        let result = "";
        if (hours > 0) result += `${hours} hr `;
        if (minutes > 0) result += `${minutes} min `;
        if (seconds > 0) result += `${seconds} sec`;

        return result.trim();
    };

    const processedData = response.map(job => {
        const breakSeconds = job.totalBreakDuration ? job.totalBreakDuration : 0;
        const totalSeconds = (job.totalDuration ?? 0) - breakSeconds;

        // Calculate hours
        const overtimeSeconds = totalSeconds > MAX_HOURS * SECONDS_IN_AN_HOUR ? totalSeconds - MAX_HOURS * SECONDS_IN_AN_HOUR : 0;

        return {
            empId: 'EMP' + job.employeeId,
            employeeName: job.employeeName,
            ssnNo: job.ssnNo,
            jobName: job.jobName,
            date: moment.utc(job.sessionDate).local().format("DD-MM-YYYY"),
            totalHours: formatDuration(job.totalDuration),
            overtimeHours: formatDuration(overtimeSeconds),
            breakTime: formatDuration(breakSeconds),
            productiveHours: formatDuration(totalSeconds),

            // normalPay: '$' + normalPay.toFixed(2),
            // overTimeRate: '$' + overTimeRate,
            // overtimePay: '$' + overtimePay.toFixed(2),
            // totalPay: '$' + (normalPay + overtimePay).toFixed(2),
        };
    });

    return processedData;
};

