import React, { useRef, useState, useEffect } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import SignatureCanvas from "react-signature-canvas";
import { PDFDocument } from "pdf-lib";
import axios from "axios";

// Import pdfjs-dist worker
import { GlobalWorkerOptions } from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.min.js";
import { uploadDocument } from "../../store/AssignedDocument/assignedDocumentSlice";
import { useDispatch } from "react-redux";

// Set worker
GlobalWorkerOptions.workerSrc = pdfjsWorker;

const ESignModal = ({ isOpen, documentId, fileUrl, onClose, onUpload }) => {
    const sigCanvas = useRef(null);
    const [pdfData, setPdfData] = useState(null);
    const dispatch = useDispatch();
    const handleClose = () => {
        setPdfData(null); // Clear PDF data
        if (sigCanvas.current) sigCanvas.current.clear(); // Clear signature
        onClose(); // Call parent onClose function
    };

    useEffect(() => {
        if (isOpen) fetchPdf();
    }, [isOpen]);

    const fetchPdf = async () => {
        try {
            console.log("Fetching PDF from:", fileUrl);
            const response = await axios.get(fileUrl, { responseType: "blob" });
            if (response.status !== 200) throw new Error("Failed to fetch PDF");
            const pdfBlob = new Blob([response.data], { type: "application/pdf" });
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfData(pdfUrl);
        } catch (error) {
            console.error("Error loading PDF:", error);
        }
    };

    const handleClearSignature = () => sigCanvas.current.clear();

    const handleSaveSignature = async () => {
        if (!pdfData || sigCanvas.current.isEmpty()) {
            alert("Please sign before saving.");
            return;
        }
    
        const pdfDoc = await PDFDocument.load(await (await fetch(pdfData)).arrayBuffer());
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        
        const { width, height } = firstPage.getSize(); // Get page size
    
        // Convert signature to image
        const signatureData = sigCanvas.current.toDataURL("image/png");
        const signatureImageBytes = await fetch(signatureData).then((res) => res.arrayBuffer());
        const signatureImage = await pdfDoc.embedPng(signatureImageBytes);
    
        // Define signature dimensions
        const signWidth = 200;
        const signHeight = 50;
        
        // Position signature at the bottom right
        const x = width - signWidth - 20; // 20px margin from right
        const y = 20; // 20px margin from bottom
    
        firstPage.drawImage(signatureImage, { x, y, width: signWidth, height: signHeight });
    
        // Save signed PDF
        const signedPdfBytes = await pdfDoc.save();
        const signedPdfBlob = new Blob([signedPdfBytes], { type: "application/pdf" });
    
        // Prepare for upload
        const formData = new FormData();
        formData.append("file", signedPdfBlob, "signed-document.pdf");
        formData.append("documentId", documentId);
    
        try {
            const downloadUrl = URL.createObjectURL(signedPdfBlob);
            const a = document.createElement("a");
            a.href = downloadUrl;
            a.download = "signed-document.pdf";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(downloadUrl);

            await dispatch(uploadDocument(formData)).unwrap();
                onClose();
                onUpload();

        } catch (error) {
            console.error("Upload failed:", error);
        }
    };

    return (
        isOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                <div className="bg-white p-6 rounded-md shadow-lg w-3/4">
                    <h2 className="text-xl font-semibold mb-4">E-Sign Document</h2>
                    <div className="flex">
                        {/* PDF Preview */}
                        <div className="w-3/4 border h-96 overflow-auto">
                            {pdfData ? <Viewer fileUrl={pdfData} /> : <p>Loading PDF...</p>}
                        </div>

                        {/* Signature Canvas */}
                        <div className="w-1/4 flex flex-col items-center">
                            <SignatureCanvas ref={sigCanvas} penColor="black" canvasProps={{ width: 200, height: 100, className: "border" }} />
                            <button onClick={handleClearSignature} className="bg-red-500 text-white px-3 py-1 mt-2">Clear</button>
                        </div>
                    </div>

                    <div className="mt-4 flex justify-end">
                        <button onClick={handleClose} className="bg-gray-500 text-white px-4 py-2 mr-2">Cancel</button>
                        <button onClick={handleSaveSignature} className="bg-blue-500 text-white px-4 py-2">Sign & Upload</button>
                    </div>
                </div>
            </div>
        )
    );
};

export default ESignModal;
