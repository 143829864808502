import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { fetchAssignedDocuments, uploadDocument } from "../../store/AssignedDocument/assignedDocumentSlice";
import { docUrl } from "../common/BaseUrl";
import ESignModal from "../popup/ESignModal";

const Documents = () => {
  const [data, setData] = useState([]);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isESignModalOpen, setIsESignModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDocId, setSelectedDocId] = useState(null);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);

  const empId = localStorage.getItem("_id");
  const { assignedDocuments } = useSelector((state) => state.assignedDocuments);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAssignedDocuments(empId));
  }, [empId, dispatch]);

  useEffect(() => {
    setData(assignedDocuments);
  }, [assignedDocuments]);

  // Open E-Sign popup with the submitted document
  const handleESignClick = (documentId, fileUrl) => {
    setSelectedDocId(documentId);
    setSelectedFileUrl(docUrl + fileUrl);
    setIsESignModalOpen(true);
  };





  const columns = [
    { name: "Employer Name", selector: (row) => row?.assignedBy?.name, sortable: true },
    { name: "Assigned Doc", selector: (row) => row?.documentId?.docIdentity, sortable: true },
    {
      name: "Doc Name (click to view)",
      cell: (row) => (
        <a href={docUrl + row?.documentId?.fileUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
          {row?.documentId?.fileName}
        </a>
      ),
      sortable: true,
    },
    {
      name: "Submitted File",
      cell: (row) =>
        row?.submittedFileUrl == null ? (
          <button
            onClick={() => handleESignClick(row?._id,row?.documentId?.fileUrl)}
            className="bg-green-500 text-white px-3 py-1 rounded-md hover:bg-green-600"
          >
            E-Sign
          </button>
        ) : (
          <a href={docUrl + row?.submittedFileUrl} target="_blank" rel="noopener noreferrer" className="text-green-500 underline">
            View File
          </a>
        ),
      sortable: false,
    },
    { name: "Status", selector: (row) => row?.status, sortable: true },
    { name: "Date", selector: (row) => new Date(row?.createdAt).toLocaleDateString(), sortable: true },
  ];

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <header className="bg-green-600 p-4 flex justify-between items-center rounded-md mb-6 shadow-md">
        <h1 className="text-2xl font-semibold text-white">Assigned Documents</h1>
      </header>
      <div className="bg-white rounded-lg p-6">
        <DataTable columns={columns} data={data || []} pagination highlightOnHover striped />
      </div>

      {/* E-Sign Modal */}
      <ESignModal
        isOpen={isESignModalOpen}
        documentId={selectedDocId}
        fileUrl={selectedFileUrl}
        onClose={() => setIsESignModalOpen(false)}
        onUpload={() => dispatch(fetchAssignedDocuments(empId))}
      />
    </div>
  );
};

export default Documents;
