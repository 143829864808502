import React from "react";
import Navbar from "../common/navBar";
import Footer from "../common/footer";

export default function NotFound() {
  return (
    <>
    <Navbar/>
      <div className="text-center p-14  font-bold text-2xl">Not Found</div>
      <Footer/>
    </>
  );
}
