import React, { useEffect } from "react";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";
import { socketUrl } from "../Page/common/BaseUrl";

const socket = io(socketUrl, {
    query: { userId: localStorage.getItem("_id") }  
});

const SocketProvider = ({ children }) => {
    const user = localStorage.getItem("_id");

    useEffect(() => {
        if (user) {
            socket.emit("user_connected", { userId: user });
        }

        const handleBeforeUnload = () => {
            if (socket.connected && user) {
                socket.emit("pageReloading", { userId: user });
                console.log("Sent pageReloading event for user:", user);
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            socket.disconnect(); // Cleanup on unmount
        };
    }, [user]);

    return <>{children}</>;
};

export { SocketProvider, socket };
