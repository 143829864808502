import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BaseUrl } from "../../Page/common/BaseUrl";

const options = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
};


export const fetchAssignedDocuments = createAsyncThunk(
    'assignedDocument/fetchAssignedDocuments',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${BaseUrl}/emp/assignedDocuments`, { headers: options });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


export const uploadDocument  = createAsyncThunk(
    'employeeAssignedDocument/uploadDocument ',
    async (formData, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.post(`${BaseUrl}/emp/uploadDoucment`, formData,   {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                  'Content-Type': 'multipart/form-data',
                }
              });

            dispatch(fetchAssignedDocuments());
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

const assignedDocumentSlice = createSlice({
    name: 'assignedDocuments',
    initialState: {
        assignedDocuments: [{
            _id:"555s"
        }],
        employeeAssignedDocuments: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAssignedDocuments.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAssignedDocuments.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.assignedDocuments = action.payload;
            })
            .addCase(fetchAssignedDocuments.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(uploadDocument.pending, (state) => {
                state.status = "loading";
              })
              .addCase(uploadDocument.fulfilled, (state, action) => {
                state.status = "success";
              })
              .addCase(uploadDocument.rejected, (state) => {
                state.status = "failed";
              });
    },
});

export default assignedDocumentSlice.reducer;
